function Message(){
    const message = document.createElement('p');
    message.innerHTML = `<b>*</b> HaxBall main logo is a link back to home settings of the page. <b>*</b>
    <br>
    <b>**</b> Tables are sortable via clicking the field header. On mobile, click on any row <b>**</b>
    <br>
    <br>
    Playoff trees for all seasons currently being developed. Out of 4 seasons there have been 3 different playoff formats. Seasons 1 and 4 are the same format so they are currently available. Seasons 2 and 3 are being worked on. 
    `

    return message;
}

export default Message;